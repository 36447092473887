import { Layout } from 'antd';
import { Auth, Hub } from "aws-amplify";
import jwt from 'jwt-decode';
import { DateTime } from 'luxon';
import React, { useContext, useEffect } from 'react';

// Local Imports
import { AuthContext } from './contexts/AuthProvider';
import Login from "./Login";
import MenuSider from './Sider';

// CSS Imports
import 'antd/dist/antd.min.css';
import './App.less';
// ======================================================================

const { Content } = Layout;


const App = () => {
  const [AuthState, AuthDispatch] = useContext(AuthContext);

  useEffect(() => {
    const user = localStorage.getItem('user') || null;
    const token = localStorage.getItem('token') || null;
    console.log("user=>" + user); //DEBUG AAM
    console.log("token=>" + token); //DEBUG AAM
    if (user && token) {

      //Check token validity. Relogin if necessary
      let userTokenExpEpochDate = jwt(token).exp;
      let currentEpochDate = DateTime.local().toSeconds().toString().split('.')[0]
      console.log(`currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`)
      if (currentEpochDate >= userTokenExpEpochDate) {
        //Token Expired
        console.log("Token expired, Signout in progress");
        Auth.signOut();
        localStorage.clear();
        let token = null;
        let user = null;
        AuthDispatch({
          type: 'LOGOUT',
          payload: {
            user,
            token
          }
        })
      } else {
        //token valid
        console.log("Token valid, Sigin in progress");
        AuthDispatch({
          type: 'LOGIN',
          payload: {
            user,
            token
          }
        })
      }
    }
  }, [])

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.dir(process.env);

      switch (event) {
        case "signIn":
          console.log("signIn in progress");
          console.dir(data);
          break;

        case "cognitoHostedUI":
          console.log("In cognitoHostedUI");
          console.dir(data);

          Auth.currentSession().then(session => {
            console.dir(session);
            const user = session.idToken.payload.identities[0].userId || null;
            const token = session.accessToken.jwtToken;
            const posix_group = session.idToken.payload["custom:posix_group"]
            // const token = session.idToken.jwtToken;
            console.log("session user=>" + user); //DEBUG AAM
            console.log("session token=>" + token); //DEBUG AAM
            console.log("posix group =>" + posix_group);

            //Check token validity. Relogin if necessary
            let userTokenExpEpochDate = jwt(token).exp;
            let currentEpochDate = DateTime.local().toSeconds().toString().split('.')[0]
            console.log(`currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`)
            if (currentEpochDate >= userTokenExpEpochDate) {
              //Token Expired
              console.log("Token expired, Signout in progress");
              Auth.signOut();
              localStorage.clear();
              let token = null;
              let user = null;
              AuthDispatch({
                type: 'LOGOUT',
                payload: {
                  user,
                  token
                }
              })
            } else {
              //token valid
              console.log("Token valid, Sigin in progress");
              AuthDispatch({
                type: 'LOGIN',
                payload: {
                  user,
                  token
                }
              })
            }
          });
          break;

        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          break;

        case "signOut":
          console.log("signOut in progress");
          console.dir(data);
          localStorage.clear();
          let token = null;
          let user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "oAuthSignOut":
          console.log("oAuthSignOut in progress");
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "signIn_failure":
          console.log("signIn_failure ");
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "cognitoHostedUI_failure":
          console.log("cognitoHostedUI_failure", data);
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        default:
          console.dir(data);
          console.dir(event);
          console.log("In Hub Default option.");
      }
    });
  }, []);



  // const components = {
  //   1: <PointingToolManger />,
  //   2: <ScrumTools />,
  //   // 3: <h2 style={{textAlign: 'center'}}>Availability Calendar Will Come Here</h2>
  // };

  // const handleMenuClick = (menu) => {
  //   updateRender(menu.key);
  // };


  return (
    <div>
      {console.dir(AuthState)}
      {console.log("AuthState.isAuthenticated " + AuthState.isAuthenticated)}
      {AuthState.isAuthenticated === false ?
        <Login />
        :
        <Layout className="site-layout">
          <MenuSider />
          {/* <MenuSider handleClick={handleMenuClick} />
                    <Content>{components[render]}</Content>
                    */}
        </Layout>
      }
    </div>
  )
}

export default App;